<script context="module" lang="ts">
	const SCROLL_TO_TOP_DELAY = 150;

	/**
	 * Returns the name of the publisher or profile associated with the given feed item.
	 * If neither a publisher nor profile is found, returns 'tickrr.io'.
	 * @param item The feed item to retrieve the publisher name from.
	 * @returns The name of the publisher or profile associated with the given feed item.
	 */
	export const getPublisherName = (item: FEFeedItem): string => {
		switch (item.type) {
			case 'DAILY_DISCUSSION':
			case 'UPDATE':
				return 'tickrr.io';
			case 'ARTICLE':
				return item.publisher?.name ?? 'UNKNOWN NAME';
			case 'POST':
				return item.profile?.username ?? 'UNKNOWN USERNAME';
			default:
				return 'UNKNOWN NAME';
		}
	};

	/**
	 * Scrolls the feed item viewer to the top.
	 */
	export function scrollFeedItemViewerToTop() {
		const container = document.querySelector('[data-testid=modal-backdrop]');
		const scrollTop = container?.scrollTop;

		if (!container) return;
		if (scrollTop === 0) return; // No need to scroll if already at top.

		setTimeout(() => {
			container?.scrollTo({
				behavior: 'smooth',
				top: 0
			});
		}, SCROLL_TO_TOP_DELAY);
	}
</script>

<script lang="ts">
	import type { FEFeedItem } from '@tickrr/db';

	import _ from 'lodash';

	import type { YMALItem, YMALItemClickDetails } from './side-panel/YouMayAlsoLike.svelte';

	import { getStores } from '../../../../../stores';
	import NavigationIndicator from '../../../elements/loaders/NavigationIndicator.svelte';
	import ModalCloseButton from '../../../modals/ModalCloseButton.svelte';
	import StickyModalWrapper from '../../../modals/StickyModalWrapper.svelte';
	import ReadArticleButton from '../../feed/_internal/ReadArticleButton.svelte';
	import FeedItemViewer from '../FeedItemViewer.svelte';
	import NavButtons from './/NavButtons.svelte';
	import StickyArticleInfo from './/StickyArticleInfo.svelte';
	import { scrollCommentInputIntoViewAndFocus } from './main/comment-input/NewCommentForm.svelte';

	const { feedItemListStore: itemListStore } = getStores(['feedItemListStore']);

	export let item: FEFeedItem;
	export let onNextClick: (next: FEFeedItem) => void;
	export let onPrevClick: (prev: YMALItem) => void;
	export let onYmalItemClick: (ymalItem: FEFeedItem) => void;
	export let focusCommentInput: boolean;

	// let YMALItems: FEFeedItem[];
	let activeIdx: number = $itemListStore.findIndex((i) => i.id === item.id) ?? 0;

	$: if (focusCommentInput === true) {
		scrollCommentInputIntoViewAndFocus({ delay: 100 });
	}

	const handleNextClick = (items: FEFeedItem[], currIdx: number) => {
		// Note how we do not-reassign the global `item` var because it will be reactively set above.
		activeIdx = currIdx + 1 < items.length ? currIdx + 1 : 0;
		const next = items[activeIdx];
		onNextClick(next);
	};

	const handlePrevClick = (items: FEFeedItem[], currIdx: number) => {
		// Note how we do not-reassign the global `item` var because it will be reactively set above.
		activeIdx = currIdx - 1 >= 0 ? currIdx - 1 : items.length - 1;
		const prev = items[activeIdx];
		onPrevClick(prev);
	};

	$: handleYMALItemClick = async (e: CustomEvent<YMALItemClickDetails>) => {
		item = e.detail.item;
		const idx = _.findIndex([...$itemListStore], (i) => i.id === item?.id);

		// If the item is not currently available in the feed, append it to the feed.
		if (idx === -1) {
			$itemListStore = [...$itemListStore, item];
			activeIdx = $itemListStore.length - 1;
		} else {
			activeIdx = idx;
		}

		// eslint-disable-next-line svelte/infinite-reactive-loop
		onYmalItemClick(item);
	};

	// Reactively scroll to top on each item change.
	$: {
		activeIdx;
		scrollFeedItemViewerToTop();
	}

	// $: if (dev) {
	// 	logger.debug({ title: item?.title }, 'Feed item viewer opened');
	// }
</script>

<StickyModalWrapper dataId={item.id} isFeedItemViewer testid="feed-item-viewer-modal">
	<!-- HEADER SLOT: insert Nav Buttons -->
	<div
		slot="header-lead"
		class="flex w-full items-center justify-between gap-x-2 overflow-hidden pr-4"
		let:isStuck
	>
		<!-- HEADER ELEMENTS -->
		<NavButtons
			on:nextClick={() => handleNextClick($itemListStore, activeIdx)}
			on:prevClick={() => handlePrevClick($itemListStore, activeIdx)}
		/>

		{#if isStuck}
			<StickyArticleInfo
				itemType={item.type}
				publisherName={getPublisherName(item)}
				title={item.title}
			/>
		{/if}
	</div>

	<svelte:fragment slot="header-trail">
		<ReadArticleButton type={item.type} url={item.url} />
		<ModalCloseButton />
	</svelte:fragment>

	<svelte:fragment slot="navigation-indicator">
		<NavigationIndicator />
	</svelte:fragment>

	<!-- ITEM VIEWER -->
	<FeedItemViewer {item} on:YMALClick={handleYMALItemClick} />
</StickyModalWrapper>
