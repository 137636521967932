<script lang="ts">
	import { createEventDispatcher } from 'svelte';

	const dispatch = createEventDispatcher<{
		nextClick: void;
		prevClick: void;
	}>();

	const onClickNext = () => dispatch('nextClick');

	const onClickPrevious = () => dispatch('prevClick');
</script>

<div
	data-testid="feed-item-viewer-modal__nav-buttons"
	class="btn-group shrink-0 tablet:variant-ghost [&>button]:py-3"
>
	<button
		aria-label="Navigate to previous item"
		data-testid="feed-item-viewer-modal__previous-button"
		type="button"
		on:click|stopPropagation={onClickPrevious}
	>
		<iconify-icon icon="tabler:chevron-left" width="20" />
	</button>

	<button
		aria-label="Navigate to next item"
		data-testid="feed-item-viewer-modal__next-button"
		type="button"
		class="!border-l-0"
		on:click|stopPropagation={onClickNext}
	>
		<iconify-icon icon="tabler:chevron-right" width="20" />
	</button>
</div>
