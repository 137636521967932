<script lang="ts">
	import type { FEFeedItem } from '@tickrr/db';

	import { browser } from '$app/environment';
	import { beforeNavigate, pushState } from '$app/navigation';
	import { page } from '$app/stores';
	import { getModalStore } from '@skeletonlabs/skeleton';
	import { onDestroy, onMount } from 'svelte';

	import type { YMALItem } from './side-panel/YouMayAlsoLike.svelte';

	import FivModal from './FIVModal.Shared.svelte';
	import NoFIVModalPageState from './NoFIVModalPageState.svelte';

	const modalStore = getModalStore();

	export let item = ($modalStore[0].meta.item as FEFeedItem) ?? null;
	export let focusCommentInput = $modalStore[0].meta.focusCommentInput ?? false;

	let initialRoute = $page.url.pathname;
	let itemHistory: FEFeedItem[] = [];
	let removeListener: (() => void) | undefined = undefined;

	const onNextClick = (next: FEFeedItem) => {
		item = next;
		itemHistory.push(next);
		pushState(`/i/${next.id}`, {});
	};

	const onPrevClick = (prev: FEFeedItem) => {
		item = prev;
		itemHistory.push(prev);
		pushState(`/i/${prev.id}`, {});
	};

	const onYmalItemClick = (ymalItem: YMALItem) => {
		item = ymalItem;
		itemHistory.push(ymalItem);
		pushState(`/i/${ymalItem.id}`, {});
	};

	const attachPopStateListener = () => {
		if (!browser) {
			return;
		}

		const handlePopState = () => {
			if (itemHistory.length > 1) {
				itemHistory.pop();
				item = itemHistory[itemHistory.length - 1];
				pushState(`/i/${item.id}`, {});
			} else {
				modalStore.close();
				pushState(initialRoute, {});
			}
		};

		window.addEventListener('popstate', handlePopState);

		return () => {
			window.removeEventListener('popstate', handlePopState);
		};
	};

	onMount(() => {
		initialRoute = $page.url.pathname;
		itemHistory = [item];
		pushState(`/i/${item.id}`, {});

		removeListener = attachPopStateListener();

		return () => {
			if (removeListener) removeListener();
		};
	});

	// We only push the initial route in onDestroy in the event that we are NOT navigating
	// (i.e., that the user has NOT clicked a link within the modal, but is instead simply closing it).
	// This is because the onDestroy hook will otherwise be called AFTER navigation (as specified in
	// our root +layout.svelte file), which results in the pushState call below being executed AFTER the
	// URL has already been changed by the navigation event.
	let willNavigate = false;
	beforeNavigate(() => {
		willNavigate = true;
		pushState(initialRoute, {});
		if (removeListener) removeListener();
	});

	onDestroy(() => {
		if (!willNavigate) pushState(initialRoute, {});
		if (removeListener) removeListener();
	});
</script>

{#if !item}
	<NoFIVModalPageState />
{:else}
	<FivModal {focusCommentInput} {onNextClick} {onPrevClick} {onYmalItemClick} bind:item />
{/if}
