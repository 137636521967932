<script>
	import { page } from '$app/stores';
	import { getModalStore } from '@skeletonlabs/skeleton';

	const modalStore = getModalStore();
</script>

<div
	class="card mt-[25vh] max-w-xs animate-[fadeIn_100ms_ease-in-out_300ms_forwards] space-y-6 bg-component p-4 text-center opacity-0 !ring-base duration-200 tablet:max-w-lg tablet:p-8"
>
	<h2 class="h3 font-bold text-primary-500">Unknown Error</h2>

	<div class="space-y-6 tablet:space-y-3">
		<p>
			An unknown error occurred. Please try <a
				data-sveltekit-reload
				href={$page.url.href}
				class="anchor">refreshing the page</a
			>.
		</p>

		<p>
			If the problem persists, please reach out to us at <a
				href="mailto:support@tickrr.io"
				class="anchor">support@tickrr.io</a
			>.
		</p>
	</div>

	<button
		type="button"
		class="variant-filled btn w-full"
		on:click={() => {
			modalStore.clear();
			window.location.reload();
		}}
	>
		<span>Refresh the page</span>
	</button>
</div>
