<script lang="ts">
	import type { FEFeedItem } from '@tickrr/db';

	import { getModalStore } from '@skeletonlabs/skeleton';

	import type { YMALItem } from './side-panel/YouMayAlsoLike.svelte';

	import FivModal from './FIVModal.Shared.svelte';
	import NoFIVModalPageState from './NoFIVModalPageState.svelte';

	const modalStore = getModalStore();

	export let item = $modalStore[0].meta.item ?? null;
	export let focusCommentInput = $modalStore[0].meta.focusCommentInput ?? false;

	const onNextClick = (next: FEFeedItem) => {
		item = next;
	};

	const onPrevClick = (prev: FEFeedItem) => {
		item = prev;
	};

	const onYmalItemClick = (ymalItem: YMALItem) => {
		item = ymalItem;
	};
</script>

{#if !item}
	<NoFIVModalPageState />
{:else}
	<FivModal {focusCommentInput} {onNextClick} {onPrevClick} {onYmalItemClick} bind:item />
{/if}
