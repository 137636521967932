<script lang="ts">
	import { focusOnMount } from '../../../actions';
	import StickyContainer from '../elements/sticky/StickyContainer.svelte';
	import StickyHeader from '../elements/sticky/StickyHeader.svelte';

	// Props...
	export let testid: string;
	export let classes = '';
	export let useHeader = true;
	export let isFeedItemViewer: boolean = false;
	export let dataId: string | undefined = undefined;
	// Note: these values are very important for the FeedItemViewer.
	// Do not change these defaults.
	export let width: string = 'w-full max-w-xl tablet:max-w-4xl laptop:max-w-5xl';

	// State...
	let isStuck = false; // for sticky header
</script>

<section
	id={testid}
	data-id={dataId}
	data-testid={testid}
	class="
	card
	min-h-fit
	border
	border-base
	{width}
	!rounded-container-token
	{classes}
	"
	use:focusOnMount={{ delay: 0, enabled: true }}
>
	{#if useHeader}
		<StickyContainer bind:isStuck>
			<!-- HEADER -->
			<StickyHeader slot="sticky-header" isFeedItemViewerHeader={isFeedItemViewer} {isStuck}>
				<slot name="header-lead" slot="header-lead" {isStuck}>
					<div class="flex-1" />
				</slot>
				<slot name="header-trail" slot="header-trail" />
				<slot name="navigation-indicator" slot="navigation-indicator" />
			</StickyHeader>

			<!-- CONTENT / BODY -->
			<slot />
			<!-- / --- / --- / -->
		</StickyContainer>
	{:else}
		<slot />
	{/if}
</section>
