<script lang="ts">
	import { sticky } from '../../../../actions';

	export let isStuck = false;
	export let topOffset: number = 0;

	const handleStuck = (e: CustomEvent<{ isStuck: boolean }>) => {
		isStuck = e.detail.isStuck;
	};
</script>

<div data-testid="sticky-container" class="flex min-h-full flex-col">
	<div
		data-position="top"
		class="sticky-header"
		class:isStuck
		on:stuck={handleStuck}
		use:sticky={{ topOffset }}
	>
		<slot name="sticky-header" />
	</div>

	<slot />
</div>

<style lang="postcss">
	.sticky-header {
		@apply sticky top-0;
	}

	.sticky-header.isStuck {
		@apply z-50 border-b border-surface-300/50 bg-component shadow-xl;
	}
</style>
