import type { ModalSettings, ModalStore } from '@skeletonlabs/skeleton';
import type { FEFeedItem } from '@tickrr/db';

import ExtensionFIVModal from './_internal/FIVModal.Extension.svelte';
import WebappFIVModal from './_internal/FIVModal.Webapp.svelte';

const extFeedItemViewerSettings: ModalSettings = {
	component: { ref: ExtensionFIVModal },
	type: 'component'
};

const feedItemViewerSettings: ModalSettings = {
	component: { ref: WebappFIVModal },
	type: 'component'
};

export const openFIVModal = ({
	focusCommentInput = false,
	isExtension,
	item,
	modalStore
}: {
	focusCommentInput?: boolean;
	isExtension: boolean;
	item: FEFeedItem;
	modalStore: ModalStore;
}) => {
	modalStore.trigger({
		...(isExtension ? extFeedItemViewerSettings : feedItemViewerSettings),
		meta: {
			focusCommentInput,
			item
		}
	});
};
